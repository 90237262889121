<template>
    <div class="where">
        <h2 class="titleBlock" v-html="pageTitle"></h2>
        <div class="where__content">
            <template>
                <swiper id="sliderWhere" class="swiper" :options="swiperOption"></swiper>
            </template>
            <div class="slider-button-line">
                <div class="slider-button-line__box">
                    <button class="slider-button" id="slider-button-prev" slot="button-prev"><i class="far fa-angle-left"></i></button>
                    <button class="slider-button" id="slider-button-next" slot="button-next"><i class="far fa-angle-right"></i></button>
                </div>
                <div class="slide-scrollBar">
                    <div class="slide-drag"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import PopUp from '@/components/PopUp'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import { HTTP, Routes } from '../API'
    export default {
        name: 'swiper-example-free-mode',
        title: 'Free mode / No fixed positions',
        props:{
            langContent:{type: String, default: 'ru'},
        },
        data(){
            return{
                show: false,
                removeScrollButton: true,
                swiperOption: {
                    loop: false,
                    slidesPerView: 3,
                    slidesPerColumn: 1,
                    spaceBetween: 80,
                    watchOverflow: true,
                    scrollbar: {
                        el: '.slide-scrollBar',
                        draggable: true,
                        dragSize: 'auto',
                        dragClass: 'slide-drag',
                        snapOnRelease: true
                    },
                    navigation: {
                        nextEl: '#slider-button-next',
                        prevEl: '#slider-button-prev'
                    },
                },
                where: [],
                pageTitle: null,
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            appPopUp: PopUp,
        },
        methods:{
            // Кол-во слайдов
            createdWhereBlock() {
                setTimeout(()=>{
                    let slider = this.$children[0].$swiper
                    // Кол-во адрессов
                    let whereLength = this.where.length
                    // Количество адресов в слайде 
                    let amountInBlock = 4
                    // Сколько слайдов
                    let numberSlide = Math.ceil(whereLength/amountInBlock)
                    // Кол-во слайдов
                    for (let index = 0; index < numberSlide ;index++) {
                        slider.appendSlide(`
                            <div class="swiper-slide">
                                <div class="where__block"></div>
                            </div>
                        `)
                    }
                    this.addWhereInfo(amountInBlock,slider)
                },400)
            },
            // Добавить адреса в слайд
            addWhereInfo (amountInBlock,slider){
            let newArray = []
            let number = 0
            let slide = document.getElementsByClassName('where__block')
            for (let index = 0; index < this.where.length; index++) {
                    newArray.push(this.where[index])
                    if(slide[number].children.length < 3){
                        this.createtInfo(newArray,slide,number)
                    }  else {
                        this.createtInfo(newArray,slide,number)
                        number ++
                    }
                    newArray = []
            }
            slider.update()
            },
            // Создать адреса
            createtInfo(newArray,slide,number) {
                let whereBox = document.createElement('div')
                whereBox.setAttribute('class','where__box')
                let img = document.createElement('img')
                img.src = newArray[0].preview_image
                whereBox.append(img)
                let whereText = document.createElement('div')
                whereText.setAttribute('class','where__text')
                whereText.innerHTML = newArray[0].title 
                
                whereBox.append(whereText)
                slide[number].appendChild(whereBox)
            },
            getContent(){
                HTTP.get(Routes.shops)
                .then(  response => {
                if(response.data.err === 0){
                    // Адреса
                    this.where = response.data.shops.items
                    // Заголовок
                    this.pageTitle = response.data.shops.title
                }
                })
                .catch( error => {
                    console.log(error);
                });
                HTTP.get(Routes.games_options)
                    .then(  response => {
                        this.numberQuest = response.data.games_options.quest
                    })
                .catch( error => {
                    console.log(error);
                });
            }
        },
        watch:{
            percent(newName) {
                localStorage.percent = newName
            },
            // langContent: function(newValue, oldValue){
            //     this.getContent();
            // }
        },
        mounted(){
            this.createdWhereBlock()
            this.getContent();
            // axios.get('http://macchoco.ru/includes/api/getData.php?type_data=shops')
            // .then(  response => {
            //     if(response.data.err === 0){
            //         // Адреса
            //         this.where = response.data.shops.items
            //         // Заголовок
            //         this.pageTitle = response.data.shops.title
            //     }
            // })
            // .catch( error => {
            //     console.log(error);
            // });
            // axios.get('http://macchoco.ru/includes/api/getData.php?type_data=games_options')
            //     .then(  response => {
            //         this.numberQuest = response.data.games_options.quest
            //      })
            // .catch( error => {
            //     console.log(error);
            // });
            setTimeout(()=>{
                const search = '#NUMBER#';
                let replaceWith = '3'
                this.numberQuest = this.numberQuest.split(search).join(replaceWith)
                this.$emit('numberQuestGame',this.numberQuest)
            },400)
        },
        beforeCreate(){
            this.show = true
            this.$emit('addLogoFooter',this.show)
            this.removeScrollButton = false
            this.$emit('removeButtonScrollFooter',this.removeScrollButton)
        },
        destroyed(){
            this.show = false
            this.$emit('addLogoFooter',this.show)
            this.removeScrollButton = true
            setTimeout(()=>{
                this.$emit('removeButtonScrollFooter',this.removeScrollButton)
            },500)
        },
    }
</script>
